export const statusOptions = [
  { title: '未开始', value: 0 },
  { title: '进行中', value: 1 },
  { title: '已完成', value: 2 },
]

export const compareOptions = [
  { label: '<=', value: '小于等于' },
  { label: '>=', value: '大于等于' },
]

export const operateLevelOptions = [
  { label: 'S0', value: 0 },
  { label: 'S1', value: 1 },
  { label: 'S2', value: 2 },
  { label: 'S3', value: 3 },
  { label: 'S4', value: 4 },
  { label: 'S5', value: 5 },
  { label: 'S6', value: 6 },
  { label: 'S7', value: 7 },
]

export const videoStatusOptions = [
  { title: '公开', value: 0 },
  { title: '私享', value: 1 },
  { title: '不公开列出', value: 2 },
]

export const videoRangeOptions = [
  { title: '公开', value: '公开' },
  { title: '私享', value: '私享' },
  { title: '不公开列出', value: '不公开列出' },
]

export const upLoadPathOptions = [
  { title: 'Reels', value: 'Reels' },
  { title: 'Watch', value: 'Watch' },
]

export const lengthOptions = [
  { text: '10', value: 10 },
  { text: '15', value: 15 },
  { text: '20', value: 20 },
  { text: '25', value: 25 },
  { text: '30', value: 30 },
]

export const pointTypeOptions = [
  { title: '文本输入', value: 'textField' },
  { title: '多行文本输入', value: 'textArea' },
  { title: '文本选择', value: 'select' },
  { title: '单项选择', value: 'radio' },
  { title: '多项选择', value: 'checkbox' },
  { title: '富文本编辑', value: 'richText' },
  { title: '文件上传', value: 'fileInput' },
]

export const isOvertimeOptions = [
  { title: '未超时', value: 0 },
  { title: '超时', value: 1 },
]

export const platformOptions = [
  { label: 'Youtube', value: 'youtube' },
  { label: 'Facebook', value: 'facebook' },
]

export const platformOptionsVo = [
  { label: 'Youtube', value: 'Youtube' },
  { label: 'Facebook', value: 'Facebook' },
]

export const domPlatformOptions = [
  { label: '西瓜', value: 'xigua' },
  { label: 'B站', value: 'bilibili' },
  { label: '抖音', value: 'douyin' },
  { label: '快手', value: 'kuaishou' },
  { label: '网易', value: 'wangyi' },
  { label: '好看', value: 'haokan' },
  { label: 'Youtube', value: 'youtube' },
]

export const domPlatformOptionsWithoutYT = [
  // { label: 'Youtube', value: 'youtube', disabled: true },
  { label: '西瓜', value: 'xigua' },
  { label: 'B站', value: 'bilibili' },
  { label: '抖音', value: 'douyin' },
  { label: '快手', value: 'kuaishou' },
  { label: '好看', value: 'haokan' },
  { label: '网易', value: 'wangyi' },
]

export const typeOptions = [
  { label: '影视', value: 'slice' },
  { label: '原创自制', value: 'original_own' },
  { label: '代运营', value: 'acting' },
  { label: '原创代运营', value: 'original_acting' },
  { label: '自运营', value: 'self' },
]

export const rulesOptions = [
  { label: '即时发布', value: 0 },
  { label: '相同预设发布时间', value: 1 },
  { label: '每天一个', value: 2 },
  { label: '每天两个', value: 3 },
  { label: '每周一个', value: 4 },
]

export const intervalOptions = [
  { label: '0.25', value: 0.25 },
  { label: '0.5', value: 0.5 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
  { label: '13', value: 13 },
  { label: '14', value: 14 },
  { label: '15', value: 15 },
  { label: '16', value: 16 },
  { label: '17', value: 17 },
  { label: '18', value: 18 },
  { label: '19', value: 19 },
  { label: '20', value: 20 },
  { label: '21', value: 21 },
  { label: '22', value: 22 },
]

// export const operationOptions = [
//   { label: '杭州FB运营组', value: 'hz_fb' },
//   { label: '晋城FB运营中心', value: 'jc_fb' },
//   { label: '晋城FB运营1组', value: 'jc_fb_1' },
//   { label: '晋城FB运营2组', value: 'jc_fb_2' },
//   { label: '晋城FB运营3组', value: 'jc_fb_3' },
//   { label: '杭州影视', value: 'hz_slice' },
//   { label: '晋城影视', value: 'jc_slice' },
//   { label: '杭州代运营', value: 'hz_mcn' },
//   { label: 'YT事业部', value: 'jc_mcn' },
//   { label: '杭州原创', value: 'hz_original' },
//   { label: '晋城原创', value: 'jc_original' },
//   { label: '上交公司', value: 'hand_over_company' },
//   { label: '郑州FB孵化组', value: 'zz_incubate' },
//   { label: '杭州FB孵化组', value: 'hz_incubate' },
//   { label: '濮阳FB孵化组', value: 'py_incubate' },
//   { label: '濮阳FB运营组', value: 'py_fb' },
//   { label: '晋城FB孵化组', value: 'jc_incubate' },
// ]

export const operationAddOptions = [
  { label: 'FB事业部', value: 'hz_fb' },
  { label: '晋城FB运营中心', value: 'jc_fb' },
  { label: '晋城FB运营1组', value: 'jc_fb_1' },
  { label: '晋城FB运营2组', value: 'jc_fb_2' },
  { label: '晋城FB运营3组', value: 'jc_fb_3' },
  { label: '杭州影视', value: 'hz_slice' },
  { label: '晋城影视', value: 'jc_slice' },
  { label: '杭州代运营', value: 'hz_mcn' },
  { label: 'YT事业部', value: 'jc_mcn' },
  { label: '杭州原创', value: 'hz_original' },
  { label: '晋城原创', value: 'jc_original' },
  { label: '上交公司', value: 'hand_over_company' },
  { label: '郑州FB孵化组', value: 'zz_incubate' },
  { label: '杭州FB孵化组', value: 'hz_incubate' },
  { label: '濮阳FB孵化组', value: 'py_incubate' },
  { label: '濮阳FB运营组', value: 'py_fb' },
  { label: '晋城FB孵化组', value: 'jc_incubate' },
  { label: '上交公司', value: 'hand_over_company' },
  { label: 'TK事业部', value: 'hz_tiktok' },
  { label: '无运营团队', value: 'none' },
]

export const operationOptionsFB = [
  { label: '无运营团队', value: 'none' },
  { label: '杭州FB运营组', value: 'hz_fb' },
  { label: '晋城FB运营1组', value: 'jc_fb_1' },
  { label: '晋城FB运营2组', value: 'jc_fb_2' },
  { label: '晋城FB运营3组', value: 'jc_fb_3' },
  { label: '杭州影视', value: 'hz_slice' },
  { label: '晋城影视', value: 'jc_slice' },
  { label: '杭州FB孵化组', value: 'hz_incubate' },
  { label: '晋城FB孵化组', value: 'jc_incubate' },
]

export const operationOptionsFBMCN = [
  { label: '杭州FB运营组', value: 'hz_fb' },
  { label: '晋城FB运营1组', value: 'jc_fb_1' },
  { label: '晋城FB运营2组', value: 'jc_fb_2' },
  { label: '晋城FB运营3组', value: 'jc_fb_3' },
  { label: '杭州FB孵化组', value: 'hz_incubate' },
  { label: '晋城FB孵化组', value: 'jc_incubate' },
]

export const operationOptionsMovie = [
  { label: '杭州影视', value: 'hz_slice' },
  { label: '晋城影视', value: 'jc_slice' },
]

export const areaOptions = [
  { label: '日本', value: 'JP' },
  { label: '韩国', value: 'KR' },
  { label: '美国', value: 'US' },
]

export const competitorOptions = [
  { label: '是', value: 1 },
  { label: '否', value: 0 },
]

export const videoFrom = [
  { label: '创作者单视频', value: 1 },
  { label: '运营本地', value: 2 },
  { label: '奇妙工坊', value: 3 },
  { label: '创作者批量文件', value: 4 },
]

export const pageOptions = [
  { title: '每页5行', value: 5 },
  { title: '每页10行', value: 10 },
  { title: '每页15行', value: 15 },
  { title: '每页20行', value: 20 },
]

export const translateStatusOptions = [
  { label: '已保存', value: 0 },
  { label: '翻译中', value: 1 },
  { label: '翻译完成', value: 2 },
  { label: '翻译失败', value: 3 },
  { label: '上传排队', value: 4 },
  { label: '上传中', value: 5 },
  { label: '上传完成', value: 6 },
  { label: '上传失败', value: 7 },
]

export const videoCategoryOptions = [
  { text: 'SHORTS视频', value: 0 },
  { text: '中长视频', value: 1 },
  { text: '全部视频', value: 2 },
]

export const bidChannelStatusOptions = [
  { text: '正常', value: 0 },
  { text: '删除', value: 1 },
]

export const submitTeamOptions = [
  { text: '杭州原创', value: 'hz_original' },
]

export const compareEqOptions = [
  { text: '>=', value: '>=' },
  { text: '<=', value: '<=' },
]

export const pastTimeOptions = [
  { text: '过去1天', value: 86400000 },
  { text: '过去2天', value: 172800000 },
  { text: '过去7天', value: 604800000 },
  { text: '过去30天', value: 2592000000 },
]

export const levelOptions = [
  { text: 'S0', value: '0' },
  { text: 'S1', value: '1' },
  { text: 'S2', value: '2' },
  { text: 'S3', value: '3' },
  { text: 'S4', value: '4' },
  { text: 'S5', value: '5' },
  { text: 'S6', value: '6' },
  { text: 'S7', value: '7' },
  { text: '无级别', value: '-1' },
]

export const channelOperationStatusOptions = [
  { text: '搁置', value: '1' },
  { text: '在运营', value: '0' },
]

export const trueOrFalseOptions = [
  { label: '是', value: 1 },
  { label: '否', value: 0 },
]

export const uploadStatusOptions = [
  // { text: '待下载', value: 0 },
  // { text: '下载中', value: 1 },
  // { text: '上传排队', value: 8 },
  { text: '下载排队', value: 7 },
  { text: '下载完成，上传排队', value: 2 },
  { text: '下载失败', value: 3 },
  { text: '未上传', value: 9 },
  { text: '上传中', value: 4 },
  { text: '上传完成', value: 5 },
  { text: '上传失败', value: 6 },
  { text: '已删除', value: 10 },
]

export const uploadStatusForMyTaskOptions = [
  // { text: '下载中', value: 1 },
  { text: '下载排队', value: 7 },
  { text: '下载完成，上传排队', value: 2 },
  { text: '下载失败', value: 3 },
  { text: '上传中', value: 4 },
  { text: '上传失败', value: 6 },
]

export const videoUploadIntervalOptions = [
  { text: '设置间隔时间', value: 1 },
  { text: '设置每一个时间点', value: 2 },
]

export const monetizationOptions = [
  { text: '不符合条件', value: 'VIDEO_MONETIZING_STATUS_NOT_MONETIZING_INELIGIBLE' },
  { text: '已开启', value: 'VIDEO_MONETIZING_STATUS_MONETIZING' },
  { text: '受限', value: 'VIDEO_MONETIZING_STATUS_MONETIZING_WITH_LIMITED_ADS' },
  { text: '空（频道不创收）', value: 'VIDEO_MONETIZING_STATUS_NOT_MONETIZING_CHANNEL_NOT_MONETIZING' },
  { text: '空（关闭创收）', value: 'VIDEO_MONETIZING_STATUS_NOT_MONETIZING_OFF' },
]

export const restrictionOptions = [
  { text: '版权', value: 'VIDEO_RESTRICTION_REASON_COPYRIGHT' },
  { text: '已设置为“面向儿童的内容”', value: 'VIDEO_RESTRICTION_REASON_TARGETED_FOR_KIDS' },
  { text: '是否适合投放广告', value: 'VIDEO_RESTRICTION_REASON_BRAND_SAFETY' },
  { text: '条款及政策', value: 'VIDEO_RESTRICTION_REASON_COMMUNITY_GUIDELINES' },
  { text: '有年龄限制', value: 'VIDEO_RESTRICTION_REASON_ADULTS_ONLY' },
]

export const privacyOptions = [
  { text: '公开', value: 1 },
  { text: '不公开列出', value: 2 },
  { text: '私享', value: 3 },
  { text: '已安排时间', value: 4 },
  { text: '在部分国家/地区禁播', value: 5 },
  { text: '已移除', value: 6 },
]

export const videoStatusUpdateOptions = [
  { text: '私享转公开', value: 0 },
  { text: '公开转私享', value: 1 },
]

export const videoStatusUpdateStatusOptions = [
  { text: '已保存', value: 0 },
  { text: '上传成功', value: 1 },
  { text: '上传失败', value: 2 },
]

export const subtitleUploadStatusOptions = [
  { text: '翻译失败', value: 1 },
  { text: '翻译成功等待上传', value: 2 },
  { text: '上传中', value: 3 },
  { text: '上传失败', value: 4 },
  { text: '上传完成', value: 5 },
]

export const subtitleUploadStatusWithoutFailOptions = [
  { text: '进行中', value: 0 },
  { text: '上传中', value: 3 },
  { text: '上传失败', value: 4 },
  { text: '上传完成', value: 5 },
]

export const videoStateStatusOptions = [
  { text: '已保存', value: 0 },
  { text: '成功', value: 1 },
  { text: '失败', value: 2 },
]

export const videoStateUpdateTypeOptions = [
  { text: '私转公', value: 1 },
  { text: '公转私', value: 2 },
]

export const pageUrlStatusOptions = [
  { text: '已关注', value: 1 },
  { text: '未关注', value: 0 },
]

export const commentHandleStatusOptions = [
  { text: '已完成', value: 1 },
  { text: '未完成', value: 0 },
]

export const specificSharedAgeOptions = [
  { text: '所有人', value: null },
  { text: '15+', value: 15 },
  { text: '18+', value: 18 },
  { text: '21+', value: 21 },
  { text: '25+', value: 25 },
]

export const cmsOptions = [
  { label: '小五', value: 'XW' },
  { label: '亚创', value: 'AC' },
  { label: 'Adsense-HK', value: 'Adsense-HK' },
  { label: 'Adsense-US', value: 'Adsense-US' },
]

export const operationType = [
  { label: '影视', value: 'slice' },
  { label: '原创', value: 'original' },
  { label: '代运营', value: 'acting' },
  { label: '影视代运营', value: 'film_acting' },
  { label: '原创代运营', value: 'original_acting' },
  { label: '原创自制', value: 'original_own' },
  { label: '自运营', value: 'self' },
]

export const operationOptions = [
  { label: 'YT事业部', value: 'jc_mcn' },
  { label: 'FB事业部', value: 'hz_fb' },
  { label: '杭州原创', value: 'hz_original' },
  { label: '杭州影视', value: 'hz_slice' },
  { label: '晋城原创', value: 'jc_original' },
  { label: '晋城影视', value: 'jc_slice' },
  { label: '上交公司', value: 'hand_over_company' },
  { label: 'TK事业部', value: 'hz_tiktok' },
]

export const operationTextOptions = [
  { label: '影视事业部', value: '影视事业部' },
  { label: 'YT事业部', value: 'YT事业部' },
  { label: '原创事业部', value: '原创事业部' },
]

export const channelTypeOptions = [
  { label: '单开', value: 0 },
  { label: '子集', value: 1 },
  { label: '合集', value: 2 },
]

// 结算单审核节点
export const checkStatusOptions = [
  { label: '待客服审核', value: 1 },
  { label: '待财务审核', value: 2 },
  { label: '待客服二审', value: 3 },
  { label: '已推送', value: 4 },
  { label: '暂不结算', value: 5 },
]

// 发票状态
export const invoiceStatusOptions = [
  { label: '待上传', value: 0 },
  { label: '待审核', value: 1 },
  { label: '审核通过', value: 2 },
  { label: '已驳回', value: 3 },
  { label: '待生成', value: 7 },
  { label: '待发起', value: 4 },
  { label: '发起失败', value: 10 },
  { label: '待签字', value: 5 },
  { label: '已签字', value: 6 },
  { label: '已生成', value: 8 },
  { label: '已确认', value: 9 },
]

// 收款人类型
export const payeeTypeOptions = [
  { label: '企业', value: 0 },
  { label: '个人', value: 1 },
  { label: '境外', value: 2 },
]

// 发票类型
export const ticketTypeOptions = [
  { label: '数电专票', value: 0 },
  { label: '数电纸专', value: 1 },
  { label: '电专', value: 2 },
  { label: '纸专', value: 3 },
  { label: '数电普票', value: 4 },
  { label: '数电纸普', value: 5 },
  { label: '电普', value: 6 },
  { label: '纸普', value: 7 },
]

// 声明类型
export const statementTypeOptions = [
  { label: '正常', value: 'normal' },
  { label: '信息变更', value: 'contractChange' },
  { label: '新增汇款', value: 'newPayment' },
]

// 汇款途径
export const remittanceChannelOptions = [
  { label: '寰球通匯', value: 1 },
  { label: 'PingPong', value: 2 },
  { label: '本地汇款', value: 3 },
  { label: 'Payoneer', value: 4 },
  { label: '银行电汇', value: 5 },

]

export const contentTypeOptions = [
  { label: 'UGC', value: 'UGC' },
  { label: 'Partner-provided', value: 'Partner-provided' },
]

// 汇款状态
export const paymentStatusOptions = [
  { label: '待汇款', value: 0 },
  { label: '已汇款', value: 1 },
  { label: '部分已汇款', value: 2 },
]

// 分成模式
export const sharePatternOptions = [
  { label: '纯分成模式', value: '纯分成模式' },
  { label: '预支分成模式', value: '预支分成模式' },
  { label: '版权采买模式', value: '版权采买模式' },
  { label: '收益梯度模式', value: '收益梯度模式' },
  { label: '其他', value: '其他' },
]

// 平台
export const platformType = [
  { label: 'YT', value: 'YT' },
  { label: 'FB', value: 'FB' },
]

// 短信发送状态
export const sendSmsStatusOptions = [
  { label: '未发送', value: 0 },
  { label: '已发送待回执', value: 1 },
  { label: '已发送', value: 2 },
  { label: '发送失败', value: 3 },
]

export const videoSuffix = ['.mov', '.mpeg-1', '.mpeg-2', '.mpeg4', '.mp4', '.mpg', '.avi', '.wmv', '.mpegps', '.flv', '.3gpp', '.webm', '.dnxhr', '.prores', '.cineform']
export const thumbnailSuffix = ['.jpg', '.jpeg', '.png']
export const subtitleSuffix = ['.srt']
