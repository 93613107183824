import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'
import base from '@/router/base'
import { isObject } from '@/@core/utils/is'
import { convertChildrenListToChildren, extractWithoutChildren, processData } from '@/@core/utils/routerUtils'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: to => {
      const accessToken = localStorage.getItem('accessToken')

      // 没有token就跳转登录
      if (!accessToken) {
        return { name: 'auth-login', query: to.query }
      }

      return { name: 'dashboard-statistics', query: to.query }
    },
  },
  ...base,

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export function resetRouter() {
  const newRouter = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
      return {
        x: 0,
        y: 0,
      }
    },
  })

  router.matcher = newRouter.matcher
}

function getRoute() {
  return new Promise(resolve => {
    useJwt.getRoute().then(async response => {
      const role = isObject(response.data.data) ? (response.data.data.routes || []) : response.data.data

      // 过滤数组中按钮类
      const roleNew = convertChildrenListToChildren(JSON.parse(JSON.stringify(role)) || [])
      // 取出路由
      const newData = processData(convertChildrenListToChildren([...role] || [], ['component']))
      const routerList = extractWithoutChildren(roleNew)

      const routerData = []
      routerList.forEach(item => {
        const userRouter = {
          path: item.path,
          name: item.route,
          // eslint-disable-next-line global-require,import/no-dynamic-require
          component: res => require([`@/views${item.path}`], res),
          meta: { ...(isObject(item.meta) ? item.meta : JSON.parse(item.meta) || {}), pageTitle: item.pageTitle || item.name },
        }
        routerData.push(userRouter)
      })

      // 存储路由
      await store.dispatch('auth/saveRouterAuth', routerData)

      // 存储按钮权限列表
      await store.dispatch('auth/saveButtonAuth', (response.data.data.buttons || []).map(item => item.route))

      // 存储数据字段权限
      await store.dispatch('auth/saveDataAuth', newData || {})

      return resolve(routerData)
    })
  })
}

let timer = null
function judgeUpdate() {
  if (localStorage.getItem(useJwt.jwtConfig.storageVersionKeyName) !== useJwt.jwtConfig.version) {
    // Message.success('版本已更新2秒后将自动刷新')
    localStorage.setItem(useJwt.jwtConfig.storageVersionKeyName, useJwt.jwtConfig.version)
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      window.location.reload()
      clearTimeout(timer)
    }, 2000)
  }
}

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
// eslint-disable-next-line consistent-return
router.beforeEach(async (to, from, next) => {
  const isLoggedIn = localStorage.getItem('accessToken')

  // 1.判断是否访问的事登录页有token就在当前页面, 没有就重置路由达到登录页
  if (to.path.toLocaleLowerCase() === '/login') {
    if (isLoggedIn && !to.query.ticket) return next(from.fullPath)

    return next()
  }

  // 2.判断是否已经登录
  if (isLoggedIn) {
    judgeUpdate()

    // 使用vuex记录是否已经加载过路由表
    if (!store.state.app.menuRouteLoaded) {
      const routerData = await getRoute()

      // vue2 写法
      routerData.push({
        path: '*',
        redirect: 'error-404',
        hidden: true,
      })
      routerData.forEach(item => router.addRoute(item))

      // 保存加载状态
      store.commit('app/MENU_ROUTE_LOADED', true)
      store.commit('app/UPDATE_NAV_TREE', routerData)

      next({ ...to, replace: true })
    }
  }

  // 3. 未登录
  if (!isLoggedIn) return next({ name: 'auth-login' })

  return next()
})

export default router
