// 数据处理将childrenList转换为children
export function convertChildrenListToChildren(obj, filterList = ['component', 'field']) {
  if (Array.isArray(obj)) {
    return obj.map(item => convertChildrenListToChildren(item, filterList))
  } if (obj !== null && typeof obj === 'object') {
    if (obj.childrenList) {
      obj.children = convertChildrenListToChildren(obj.childrenList, filterList)
      delete obj.childrenList
      obj.children = obj.children.filter(item => !filterList.includes(item.type))
      if (obj.children.length === 0) {
        delete obj.children
      }
    }

    return obj
  }

  return obj
}

// 递归将将数据中不含有children的数据取出
export function extractWithoutChildren(obj) {
  let result = []
  if (Array.isArray(obj)) {
    obj.forEach(item => {
      result = result.concat(extractWithoutChildren(item))
    })
  } else if (obj !== null && typeof obj === 'object') {
    if (!obj.children) {
      result.push(obj)
    } else {
      result = result.concat(extractWithoutChildren(obj.children))
    }
  }

  return result
}

export const dataMap = {
  'ytStatement-list': [
    {
      text: '结算单编号', value: 'settlementNo', view: true, export: true, edit: true,
    },
    {
      text: 'CP名称', value: 'cpName', view: true, export: false, edit: false,
    },
  ],
}
const testArr = [
  {
    id: 880,
    parentId: 0,
    name: '结算单管理',
    type: 'nav',
    appId: 'silverdawn-finance-server',
    icon: 'mdiTooltipCheckOutline',
    path: 'statementManagement',
    route: 'statement-management',
    groupName: 'application',
    meta: '{"layout": "content"}',
    isView: '0',
    sort: 2,
    isHidden: false,
    isBreadcrumb: true,
    has: false,
    children: [
      {
        id: 887,
        parentId: 880,
        name: 'YT结算单管理',
        type: 'nav',
        appId: 'silverdawn-finance-server',
        path: '/statementManagement/ytStatement/List',
        route: 'ytStatement-list',
        groupName: 'application',
        meta: '{"layout": "content"}',
        isView: '0',
        sort: 1,
        isHidden: false,
        isBreadcrumb: true,
        has: false,
        children: [
          {
            id: 1088,
            parentId: 887,
            name: '结算单编号',
            type: 'field',
            appId: 'silverdawn-finance-server',
            method: 'get',
            path: 'settlementNo',
            route: 'remittanceManagement-overseasRemittance-Listtest',
            groupName: 'system',
            meta: '{"layout": "content"}',
            isView: '0',
            sort: 1,
            isHidden: false,
            isBreadcrumb: true,
            has: false,
            children: [
              {
                id: 1089,
                parentId: 1088,
                name: '查看',
                type: 'oper',
                appId: 'silverdawn-finance-server',
                method: 'get',
                path: '/remittanceManagement/overseasRemittance/Listtest/oper1',
                route: 'remittanceManagement-overseasRemittance-Listtest-oper1',
                groupName: 'system',
                meta: '{"layout": "content"}',
                isView: '0',
                sort: 1,
                isHidden: false,
                isBreadcrumb: true,
                has: false,
              },
              {
                id: 1090,
                parentId: 1088,
                name: '编辑',
                type: 'oper',
                appId: 'silverdawn-finance-server',
                method: 'get',
                path: '/remittanceManagement/overseasRemittance/Listtest/oper2',
                route: 'remittanceManagement-overseasRemittance-Listtest-oper2',
                groupName: 'system',
                meta: '{"layout": "content"}',
                isView: '0',
                sort: 2,
                isHidden: false,
                isBreadcrumb: true,
                has: false,
              },
              {
                id: 1091,
                parentId: 1088,
                name: '导出',
                type: 'oper',
                appId: 'silverdawn-finance-server',
                method: 'get',
                path: '/remittanceManagement/overseasRemittance/Listtest/oper3',
                route: 'remittanceManagement-overseasRemittance-Listtest-oper3',
                groupName: 'system',
                meta: '{"layout": "content"}',
                isView: '0',
                sort: 3,
                isHidden: false,
                isBreadcrumb: true,
                has: false,
              },
            ],
          },
          {
            id: 1096,
            parentId: 887,
            name: 'CP名称',
            type: 'field',
            appId: 'silverdawn-finance-server',
            method: 'get',
            path: 'cpName',
            route: 'remittanceManagement-overseasRemittance-Listtest3',
            groupName: 'system',
            meta: '{"layout": "content"}',
            isView: '0',
            sort: 1,
            isHidden: false,
            isBreadcrumb: true,
            has: false,
            children: [
              {
                id: 1097,
                parentId: 1096,
                name: '查看',
                type: 'oper',
                appId: 'silverdawn-finance-server',
                method: 'get',
                path: '/remittanceManagement/overseasRemittance/Listtest/oper1',
                route: 'remittanceManagement-overseasRemittance-Listtest-oper1',
                groupName: 'system',
                meta: '{"layout": "content"}',
                isView: '0',
                sort: 1,
                isHidden: false,
                isBreadcrumb: true,
                has: false,
              },
            ],
          },
        ],
      },
    ],
  },
]

export function processData(arr) {
  // 定义结果对象
  const result = {}

  // 递归函数
  function traverse(items = []) {
    items.forEach(item => {
      // 如果目标路由不存在，则初始化它
      if (!result[item.route]) {
        result[item.route] = []
      }

      // 初始化字段对象
      // const fields = {}

      if (!Array.isArray(item.children)) return

      // 遍历子项以填充字段
      item.children.forEach(child => {
        // 处理子项
        if (child.type === 'field') {
          const fieldData = {
            text: child.name,
            value: child.path,
            view: false,
            export: false,
            edit: false,
          }

          // 遍历子项的操作
          if (child.children) {
            child.children.forEach(operation => {
              if (operation.name === '查看') {
                fieldData.view = true
              }
              if (operation.name === '导出') {
                fieldData.export = true
              }
              if (operation.name === '编辑') {
                fieldData.edit = true
              }
            })
          }

          // 添加到结果数组
          result[item.route].push(fieldData)
        }
      })

      // 如果当前项有子项，递归调用
      if (item.children) {
        traverse(item.children)
      }
    })
  }

  // 开始遍历传入的数组
  traverse(arr || [])

  return result
}

// // 使用 testArr 调用函数
// const formattedData = processData(testArr)
// console.log(formattedData)
