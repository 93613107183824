import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VOverlay } from 'vuetify/lib/components/VOverlay';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{staticClass:"content-layout vertical-nav",class:[{'nav-drawer-mini': _vm.menuIsVerticalNavMini}, {'content-full': _vm.appContentWidth === 'full'}]},[(!_vm.menuIsMenuHidden)?_c(VNavigationDrawer,{ref:"layoutDrawer",staticClass:"app-navigation-menu",attrs:{"app":"","right":_vm.$vuetify.rtl,"mini-variant-width":"68","expand-on-hover":_vm.menuIsVerticalNavMini,"width":"240"},model:{value:(_vm.isVerticalNavMenuActive),callback:function ($$v) {_vm.isVerticalNavMenuActive=$$v},expression:"isVerticalNavMenuActive"}},[_c('vertical-nav-menu',{attrs:{"nav-menu-items":_vm.navMenuItems},on:{"close-nav-menu":function($event){_vm.isVerticalNavMenuActive = false}}})],1):_vm._e(),(_vm.appBarType !== 'hidden')?_c(VAppBar,{ref:"refAppBar",staticClass:"mx-auto app-bar-static px-10 app-bar-shinked bg-blur",attrs:{"app":"","absolute":_vm.appBarType === 'static',"flat":"","elevate-on-scroll":_vm.appBarType !== 'static',"elevation":_vm.appBarType !== 'static' ? _vm.$vuetify.theme.isDark ? 4: 3 : 0}},[_vm._t("navbar",null,{"isVerticalNavMenuActive":_vm.isVerticalNavMenuActive,"toggleVerticalNavMenuActive":_vm.toggleVerticalNavMenuActive})],2):_vm._e(),_vm._t("v-app-content"),_c(VMain,[_c('app-content-container',{staticStyle:{"padding":"12px 20px"}},[_vm._t("default")],2)],1),_c(VOverlay,{staticClass:"content-overlay",attrs:{"value":_vm.$store.state.app.shallContentShowOverlay,"z-index":"4","absolute":""}}),(_vm.footerType !== 'hidden')?_c(VFooter,{class:{'mx-auto': _vm.appContentWidth !== 'full'},attrs:{"app":"","inset":"","absolute":_vm.footerType === 'static',"padless":"","color":_vm.footerType === 'static' ? 'transparent' : null}},[_c('div',{staticClass:"py-4 w-full",class:{'px-5': _vm.footerType === 'fixed'}},[_vm._t("footer")],2)]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }