<template>
  <v-menu
    offset-y
    left
    nudge-bottom="22"
    :elevation="$vuetify.theme.dark ? 9 : 8"
    content-class="list-style notification-menu-content"
  >
    <template #activator="{ value, on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          @click="getMsgList(value)"
        >
          {{ icons.mdiBellOutline }}
        </v-icon>
      </div>
    </template>
    <v-card
      min-width="350"
      class="app-bar-notification-content-container"
    >
      <perfect-scrollbar
        class="ps-user-notifications"
        :options="perfectScrollbarOptions"
      >
        <v-list class="py-0">
          <!-- Header -->
          <v-list-item
            class="d-flex"
            link
          >
            <div class="d-flex align-center justify-space-between flex-grow-1">
              <span class="font-weight-semibold">消息通知</span>
            </div>
          </v-list-item>
          <v-divider></v-divider>

          <div v-show="notifications.length!==0">
            <!-- Notifications -->
            <template v-for="(notification, index) in notifications">
              <v-list-item
                :key="notification.title"
                link
              >
                <!-- Avatar -->
                <v-list-item-avatar
                  :class="[{'v-avatar-light-bg primary--text justify-center': notification.user && !notification.user.avatar}]"
                  size="38"
                >
                  <v-icon>mdi-microsoft-excel</v-icon>
                </v-list-item-avatar>

                <!-- Content -->
                <v-list-item-content class="d-block">
                  <v-list-item-title class="text-sm font-weight-semibold">
                    {{ notification.channelId }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-sm">
                    导出完成！
                  </v-list-item-subtitle>
                </v-list-item-content>

                <!-- Item Action/Time -->
                <v-list-item-action>
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click="downloadExcel(notification.key)"
                      >
                        mdi-download
                      </v-icon>
                    </template>
                    <span>下载</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="index"></v-divider>
            </template>
          </div>
          <div
            v-show="notifications.length===0"
            class="ma-4 caption secondary--text text-center justify-center"
          >
            暂无导出文件
          </div>
        </v-list>
      </perfect-scrollbar>
    </v-card>
  </v-menu>
</template>

<script>
import { mdiBellOutline } from '@mdi/js'
import { getInitialName } from '@core/utils'

// 3rd Party
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { ref } from '@vue/composition-api'

export default {
  components: {
    // 3rd Party
    PerfectScrollbar,
  },
  setup() {
    const notifications = ref([])

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      getInitialName,
      perfectScrollbarOptions,
      notifications,

      icons: {
        mdiBellOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar-notification-content-container {
  .read-all-btn-list-item {
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: unset;
  }
}

.ps-user-notifications {
  max-height: calc(var(--vh, 1vh) * 80);
}

.notification-menu-content {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: calc(100vw - (1.5rem * 2)) !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
</style>
